import { mapActions, mapGetters } from 'vuex'
import { setCookie } from '@/utils/Cookie'
import { app } from '@/config'

export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters('user', ['token'])
  },
  methods: {
    ...mapActions('user', ['loginAction']),
    doLogin () {
      this.loginAction({
        data: {
          username: this.username,
          password: this.password
        },
        success: this.handleSuccess
      })
    },
    handleSuccess () {
      setCookie({
        key: app.cookies.token,
        value: this.token,
        expires: 1
      })
      this.$router.push('/dashboard')
    }
  }
}
